$desktop-width: 1280px;

@mixin desktop {
  @media screen and (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin responsive($breakpoint) {
  @if $breakpoint == xs {
    @media (max-width: 575.98px) {
      @content;
    }
  }

  @if $breakpoint == sm {
    @media (min-width: 576px) and (max-width: 767.98px) {
      @content;
    }
  }

  @if $breakpoint == md {
    @media (min-width: 768px) and (max-width: 991.98px) {
      @content;
    }
  }

  @if $breakpoint == lg {
    @media (min-width: 992px) and (max-width: 1199.98px) {
      @content;
    }
  }

  @if $breakpoint == xl {
    @media (min-width: 1200px) {
      @content;
    }
  }
}

.app-container {
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  font-family: var(--font-regular);
  font-size: 1rem;

  background: #f5f5f5;

  &__header {
    --height-mobile: 8.75rem;
    --height-desktop: 19.375rem;

    height: var(--height-mobile);
    width: 100%;
    box-shadow: 0px 0.52083px 0.52083px 0px rgba(0, 0, 0, 0.1);
    background: linear-gradient(268deg, #1890ff -4.61%, #37abff 101.92%);
    position: relative;

    &::before {
      background-image: url(./../../assets/imgs/header.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      box-shadow: 0px -0.52083px 0.78125px 0px rgba(0, 0, 0, 0.15);
      content: '';
      display: block;
      height: var(--height-mobile);
      position: absolute;
      width: 100%;
      opacity: 0.2;
      @include desktop {
        height: var(--height-desktop);
      }
    }
    @include desktop {
      height: var(--height-desktop);
    }

    .app-container__content {
      position: relative;
      width: inherit;
      z-index: 2;
      margin: 0 auto;
      @include desktop {
        width: var(--destop-width);
      }
    }

    .logo {
      left: 1.2rem;
      top: 1.8rem;

      @include desktop {
        left: 0;
        top: 58px;
      }

      img {
        @include desktop {
          width: 11.5rem;
          height: 2.75rem;
        }
      }
    }
    .header-text {
      color: var(--white);
      right: 1.2rem;
      bottom: -7rem;
      font-family: SFPro;
      font-size: 14px;
      line-height: 20px;

      .header-text-des{
        font-family: SFProBold;
      }

      @include desktop {
        right: 0rem;
        font-size: 22px;
        line-height: 30.8px;
        top: 58px;
      }
    }
  }

  &__footer {
    width: 100%;
    height: 10rem;
    background: linear-gradient(268deg, #1890ff -4.61%, #37abff 101.92%);
    box-shadow: 0px -0.60484px 0.90726px 0px rgba(0, 0, 0, 0.15);
    color: #fff;
    z-index: 11;
    margin: 0 auto;

    @include desktop {
      width: var(--destop-width);
      // height: 12.75rem;
      height: 16.25rem;
    }

    .footer-des {
      align-self: center;
    }

    .hotline-v2 {
      bottom: 0;
      left: calc(50% - 279px/2);

      .img_call {
        @include desktop() {
          width: 17.5rem;
          height: 17.5rem;
        }
      }
    }

    .hotline {
      top: -40%;
      color: var(--white);
      &-text {
        white-space: pre;
        font-size: 0.64031rem;
        font-style: normal;
        font-weight: 500;
        line-height: 0.9605rem;
        @include desktop {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
      .img_call {
        @include desktop() {
          width: 17.5rem;
          height: 17.5rem;
        }
      }
      @include desktop() {
        top: -37.5%;
      }
    }
    .footer-title {
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 0.9385rem;
      letter-spacing: 0.04694rem;
      bottom: 1rem;
      right: 1rem;

      .footer-text {
        font-family: SFProBold;
        font-size: 16px;
        line-height: 24px;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }

      @include desktop() {
        right: 5rem;
      }
    }
  }

  &__footer-v2 {
    width: 100%;
    min-height: 16.25rem;
    // height: 10rem;
    background: linear-gradient(268deg, #1890ff -4.61%, #37abff 101.92%);
    box-shadow: 0px -0.60484px 0.90726px 0px rgba(0, 0, 0, 0.15);
    color: #fff;
    z-index: 11;
    margin: 0 auto;

    @include desktop {
      width: var(--destop-width);
      height: 16.25rem;
      padding: 45px 60px 35px;
    }
    @include responsive(xs) {
      padding: 90px 16px 30px;
    }
    @include responsive(sm) {
      padding: 90px 16px 30px;
    }

    padding: 24px 48px 24px;
    display: flex;
    grid-template-columns: auto auto;
    gap: 50px;
    justify-content: space-between;

    .footer-des {
      display: flex;
      flex-direction: column;
      align-self: center;

      @include responsive(xs) {
        gap: 24px
      }
      @include responsive(sm) {
        gap: 24px
      }
    }

    .logo-section {
      right: 5%;
      bottom: 40%;

      @include responsive(xl) {
        right: unset;
        bottom: 0;
        left: 52%;
      }
      
      @include responsive(xs) {
        bottom: 80%;
      }
      @include responsive(sm) {
        bottom: 80%;
      }

      .img_call {
        // max-width: 80%;
        width: 181px;

        @include responsive(xl) {
          max-width: 100%;
          width: 17.5rem;
          height: 17.5rem;
        }
      }
    }

    .hotline-section {
      text-align: center;
      max-width: fit-content;
      margin-bottom: 8px;
      margin-left: 48px;

      @include responsive(xs) {
        margin-bottom: unset;
        margin-left: unset;
        display: flex;
        flex-direction: column;
        align-items: center;

        .hotline-image-mb {
          display: block !important;
          margin-bottom: 6px;
        }
        .hotline-image {
          display: none;
        }
      }
      @include responsive(sm) {
        margin-bottom: unset;
        margin-left: unset;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .hotline-image {
        width: auto;
        max-height: 50px;
        margin-bottom: 8px;
      }
      .hotline-image-mb {
        display: none;
      }
      
      &__text {
        color: #ffffff;
        font-family: "SFProBold";
        font-size: 12px;
        line-height: 16px;

        @include responsive(xs) {
          font-size: 0.641rem;
          font-weight: 500;
          line-height: 15.37px;
        }
      }
    }

    .info-section {
      display: grid;
      align-items: center;
      justify-content: center;
      & > div {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px;
      }

      .info-labels {
        width: 70px;
        text-align: right;
        white-space: nowrap;

        font-family: "SFProBold";
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        @include responsive(xs) {
          font-size: 0.5rem;
          font-weight: 700;
          line-height: 0.875rem;
        }
      }

      .info-details {
        font-family: "SFPro";
        font-size: 12px;
        line-height: 16px;
        text-align: left;

        @include responsive(xs) {
          font-size: 0.5rem;
          font-weight: 400;
          line-height: 0.875rem;
          & > a {
            font-size: 0.5rem;
            line-height: 0.875rem;
          }
        }

        & > a {
          font-family: "SFProBold";
          text-decoration: underline;
        }
      }
    }
    
    .hotline-image {
      width: auto;
      max-height: 50px;
      margin-bottom: 8px;
    }
    
    .hotline-text {
      font-size: 14px;
      color: #ffffff;
    }

    .footer-title {
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 0.9385rem;
      letter-spacing: 0.04694rem;
      bottom: 1rem;
      right: 1rem;


      @include desktop() {
        right: 5rem;
      }
      @include responsive(xs) {
        position: absolute;
        bottom: unset;
        right: 16px;
        flex-direction: column-reverse;
        font-size: 0.626rem;
        line-height: 0.939rem;
        
        .pci-logo {
          width: 58px !important;
        }
        .geo-trust-logo {
          width: 89px !important;
        }
        .vnpt-logo {
          width: 58px !important;
        }
      }
      @include responsive(sm) {
        position: absolute;
        bottom: unset;
        right: 16px;
        flex-direction: column-reverse;
      }
    }
  }
}

// button
.btn {
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-family: SFProB;
  font-size: 16px;
  line-height: 24px;
}
.btn-blue {
  color: var(--neutral-100, #fff);
  background: var(--primary-500, #3079ff);
  &:hover {
    box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.2);
  }
}
.btn-disable {
  background: var(--neutral-400, #c2c6c9) !important;
  box-shadow: none !important;
  cursor: default;
}

// Typography
.color-error {
  color: var(--semantic-error, #f46666);
}

.color-disable {
  color: var(--neutral-400, #c2c6c9);
}

.color-label {
  color: var(--neutral-800, #5d666f);
}

.color-sublabel {
  color: var(--neutral-600, #858c93);
}

.color-label-v2 {
  color: #4F4F4F;
}

.t26_b {
  font-family: SFProBold;
  font-size: 26px;
  font-style: normal;
  line-height: 28px;
}

.t24_b {
  font-size: 24px;
  font-style: normal;
  font-family: SFProBold;
  line-height: 32px;
}

.t22_r {
  font-size: 22px;
  font-style: normal;
  font-family: SFPro;
  line-height: 140%; /* 30.8px */
}

.t22_b {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.t20_b {
  font-size: 20px;
  font-style: normal;
  font-family: SFProBold;
  line-height: 28px;
}

.t18_b {
  font-size: 18px;
  font-style: normal;
  font-family: SFProBold;
  line-height: 26px; /* 144.444% */
}

.t16_b {
  font-size: 16px;
  font-style: normal;
  font-family: SFProBold;
  line-height: 24px;
}

.t16_r {
  font-size: 16px;
  font-style: normal; 
  font-family: SFPro;
  line-height: 24px;
}

.t14_b {
  font-size: 14px;
  font-style: normal;
  font-family: SFProBold;
  line-height: 20px;
}
.t14_m {
  font-size: 14px;
  font-style: normal;
  font-family: SFProMedium;
  line-height: 20px;
}
.t14_r {
  font-size: 14px;
  font-style: normal;
  font-family: SFPro;
  line-height: 20px;
}
.t14_italic {
  color: #666;
  font-size: 14px;
  font-style: italic;
  font-family: SFProMedium;
  line-height: 18px;
}

.t12_b {
  font-size: 12px;
  font-style: normal;
  font-family: SFProBold;
  line-height: 16px;
}
.t12_m {
  font-size: 12px;
  font-style: normal;
  font-family: SFProMedium;
  line-height: 16px;
}
.t12_r {
  font-size: 12px;
  font-style: normal;
  font-family: SFPro;
  line-height: 16px;
}

.ant-form-item {
  margin-bottom: 0;
}