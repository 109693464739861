// $mobile-width: 768px;
// $desktop-width: 1280px;

// font
@font-face {
  font-family: NotoSans;
  src: url('../../assets/fonts/NotoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: SFPro;
  src: url('../../assets/fonts/sfpro/SF-Pro-Display-Light.otf');
}
@font-face {
  font-family: SFProMedium;
  src: url('../../assets/fonts/sfpro/SF-Pro-Display-Medium.otf');
}
@font-face {
  font-family: SFProBold;
  src: url('../../assets/fonts/sfpro/SF-Pro-Display-Bold.otf');
}

:root {
  --black: #000000;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;

  --semantic-error: #f46666;
  --semantic-success: #2eb872;
  --bg-main: #f5f5f5;

  --primary-100: ;
  --primary-200: ;
  --primary-300: ;
  --primary-400: #5994ff;
  --primary-500: #3079ff;
  --primary-600: ;
  --primary-700: ;
  --primary-800: ;
  --primary-900: ;

  --neutral-100: #ffffff;
  --neutral-200: #ebeced;
  --neutral-300: ;
  --neutral-400: #c2c6c9;
  --neutral-500: #9aa0a5;
  --neutral-600: #858c93;
  --neutral-700: #717981;
  --neutral-800: #5d666f;
  --neutral-900: #5d666f;

  --destop-width: 1240px;

  --font-bold: SFProBold;
  --font-medium: SFProMedium;
  --font-regular: SFPro;
  --font-noto: NotoSans;
}
